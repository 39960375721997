<template>
  <b-message v-if="currencyCredit > 0" type="is-info">
    <p>
      {{ context }}
      <strong>{{ $formatCurrency(currencyCredit, computedCurrency) }}</strong>
      account credit which we'll automatically consume against future
      transactions.
    </p>
  </b-message>
</template>

<script>
export default {
  name: "AccountCredit",
  props: {
    userId: {
      default: null,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    },
    currency: {
      type: String,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    computedCurrency() {
      return (
        this.currency || // Passed down currency
        this.$_.get(this.user, "currency") || // Charged user currency
        this.$store.getters["user/currency"]() // Current user currency
      );
    },
    credit() {
      return this.$store.getters["billing/credit"](this.userId);
    },
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    currencyCredit() {
      return this.credit[this.computedCurrency] || 0;
    },
    context() {
      return this.$store.getters["auth/isCurrentUser"](this.userId)
        ? "You have"
        : "This user has";
    }
  },
  watch: {
    credit(credit) {
      this.$emit("balance", credit);
    }
  },
  created() {
    this.observeUser();
    this.observeCredit();
  },
  beforeDestroy() {
    this.unobserveCredit();
    this.unobserveUser();
  },
  methods: {
    observeUser() {
      return this.$store.dispatch("user/observeUser", {
        userId: this.userId
      });
    },
    unobserveUser() {
      this.$store.dispatch("user/unobserveUser", {
        userId: this.userId
      });
    },
    observeCredit() {
      if (this.userId) {
        this.$store.dispatch("billing/observeCredit", { userId: this.userId });
      }
    },
    unobserveCredit(userId) {
      this.$store.dispatch("billing/unobserveCredit", {
        userId: userId ? userId : this.userId
      });
    }
  }
};
</script>
